import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { Box, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../Common/Loading';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import useStyles from '../AdvancedTools/styled/StudyAdvancedToolsMakeStyles';
import { InnerDivHTML } from '../Common/InnerHTML';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { Image } from '../Image/Image';

const getProviderLinkText = (url) => url && url.replace(/(^\w+:|^)\/\//, '');
const getProviderLinkHref = (url) => url && `https://${getProviderLinkText(url)}`;

export const AdvancedToolDetailView = ({ advancedTool }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const dispatch = useDispatch();


  if (sessionStorage.getItem(advancedTool.uuid) === null) {
    sessionStorage.setItem(advancedTool.uuid, advancedTool && (advancedTool.creditCost === 0).toString());
  }

  const goBack = () => window.history.back();

  const handleEdit = (evt) => {
    evt.stopPropagation();
    navigate(`/advanced-tools/edit/${advancedTool.uuid}`);
  };

  let certifications = [];
  if (advancedTool && advancedTool.ceCertification) certifications = [...certifications, 'CE'];
  if (advancedTool && advancedTool.fdaCertification) certifications = [...certifications, 'FDA'];

  const creditCost = advancedTool && advancedTool.creditCost;
  const creditText = creditCost === 1 ? 'credit' : 'credits';

  const handleView = (evt) => {
    evt.stopPropagation();
    const images = [advancedTool.reportView];

    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  };

  const getApplicationPath = (tool) => {
    if (isEmpty(tool)) return null;
    return {
      advancedTool: {
        name: tool.name,
        link: `/advanced-tools/detail/${tool.uuid}`,
      },
    };
  };

  const buttons = [
    { name: 'edit.tool', icon: Edit, handleClick: handleEdit, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  return (
    <ArticleContainerUI>
      <Navbar className={styles.subheader}>
        <SectionBar
          title="advanced.tool"
          items={buttons}
          breadcrumbs={getApplicationPath(advancedTool)}
        />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer>
          {(isEmpty(advancedTool)) ? <Loading /> : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={11} lg={9}>
                <Paper elevation={2} className={styles.wrapper}>
                  { advancedTool && (
                    <Box className={styles.box}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" className={styles.toolName}>
                            {advancedTool.name && advancedTool.name.toUpperCase()}
                          </Typography>
                          <Typography variant="body1" className={styles.provider}>
                            {advancedTool.provider.name && advancedTool.provider.name.toUpperCase()}
                          </Typography>
                          <Box className={styles.bar} />
                          <Box className={styles.description}>
                            <InnerDivHTML content={advancedTool.description} />
                          </Box>
                          <Grid container spacing={1} className={styles.specifications}>
                            {certifications && !!certifications.length && (
                              <>
                                <Grid item xs={5} className={styles.specsName}>regulatory</Grid>
                                <Grid item xs={7} className={styles.specsValue}>{`${certifications.join(', ')} MARK`}</Grid>
                              </>
                            )}
                            <Grid item xs={5} className={styles.specsName}>
                              speciality
                            </Grid>
                            <Grid item xs={7} className={styles.specsValue}>
                              <Box>
                                {advancedTool.speciality && t(advancedTool.speciality.toLowerCase()).toUpperCase()}
                              </Box>
                            </Grid>
                            <Grid item xs={5} className={styles.specsName}>
                              anatomy
                            </Grid>
                            <Grid item xs={7} className={styles.specsValue}>
                              <Box>
                                {advancedTool.bodyRegion && t(advancedTool.bodyRegion.toLowerCase()).toUpperCase()}
                              </Box>
                            </Grid>
                            <Grid item xs={5} className={styles.specsName}>
                              image modality
                            </Grid>
                            <Grid item xs={7} className={styles.specsValue}>
                              <Box>
                                {advancedTool.category && advancedTool.category.toUpperCase()}
                              </Box>
                            </Grid>
                          </Grid>
                          <Box className={styles.description}>
                            <InnerDivHTML classes="ai-tool-section" content={advancedTool.info1} />
                          </Box>
                          {advancedTool.reportView && (
                            <Box className={styles.description}>
                              <Box className="ai-tool-section">
                                <Typography variant="h1">
                                  {t('report.model').toUpperCase()}
                                </Typography>
                                <Box onClick={handleView}>
                                  <Image src={advancedTool.reportView} styles={styles.reportView} />
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className={styles.image}>
                            {advancedTool.image && (
                              <img src={advancedTool.image} alt="AdvancedTool" />
                            )}
                          </Box>
                          <Box className={styles.imageFooter}>
                            <Link
                              variant="body2"
                              href={getProviderLinkHref(advancedTool.provider.web)}
                              target="_blank"
                              rel="noopener"
                              color="inherit"
                              underline="none"
                            >
                              {advancedTool.provider.web && getProviderLinkText(advancedTool.provider.web).toUpperCase()}
                            </Link>
                          </Box>
                          <Box className={styles.description}>
                            <InnerDivHTML classes="ai-tool-section" content={advancedTool.info2} />
                          </Box>
                          <Box className={styles.diseases}>
                            <InnerDivHTML content={advancedTool.info3} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className={styles.cardFooter} />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box className={styles.creditInfo}>
                                <Typography component="span">
                                  {!!creditCost && `${t('credit.price').toUpperCase()}: `}
                                </Typography>
                                <Typography component="span">
                                  {creditCost === 0 ? t('available.at.no.cost') : `${creditCost} ${t(creditText)}`}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </ScrollableContainer>
      </Container>

    </ArticleContainerUI>
  );
};
